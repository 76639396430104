<template>
  <div class="iot-help public-scroll-transparent">
    <el-card class="box-card" shadow="hover">
      <div slot="header" class="clearfix">
        <span>公告</span>
      </div>
      <div class="iot-top-card-item">
        流量查询功能即将上线
        <el-badge :value="`new`" class="item" />
      </div>
      <div class="iot-top-card-item">
        帮助文档功能即将上线
        <el-badge :value="`new`" class="item" />
      </div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <!-- <div v-for="o in 5" :key="o" class="iot-top-card-item">
        {{ `透传功能即将上线${o}` }}
        <el-badge :value="`new`" class="item" />
      </div> -->
    </el-card>
    <el-card class="box-card" shadow="hover">
      <span>方案中心</span>
      <div>
        <p>暂未开放</p>
        <div class="iot-top-card-item" style="height: 25px"></div>
        <div class="iot-top-card-item" style="height: 25px"></div>
        <div class="iot-top-card-item" style="height: 25px"></div>
        <el-link class="iot-help-link" type="primary">查看方案</el-link>
      </div>
    </el-card>
    <el-card class="box-card" shadow="hover">
      <span>样品中心</span>
      <p>暂未开放</p>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <el-link class="iot-help-link" type="primary">前往查看</el-link>
    </el-card>
    <el-card class="box-card" shadow="hover">
      <div slot="header" class="clearfix">
        <span>帮助</span>
      </div>
      <div class="iot-top-card-item">即将开放</div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <div class="iot-top-card-item" style="height: 25px"></div>
      <!-- <div v-for="o in 5" :key="o" class="iot-top-card-item">
        {{ `帮助与服务的第${o}篇文章` }}
      </div> -->
      <el-link class="iot-help-link" type="primary">文档中心</el-link><br />
      <el-link class="iot-help-link" type="primary">帮助中心</el-link>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "help",
  data() {
    return {};
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-help {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}
.iot-help >>> .el-card__header {
  padding: 10px 20px;
}
.iot-top-card-item,
.iot-help-link >>> .el-link--inner {
  line-height: 25px;
}
.iot-help .box-card {
  margin: 10px 0;
}
.iot-top-card-item,
.iot-help .box-card p,
.iot-help-link >>> .el-link--inner {
  font-size: 14px;
}
.iot-help .box-card p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.iot-help >>> .el-card__body {
  padding: 15px 20px;
}
</style>
